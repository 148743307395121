import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileContract } from "@fortawesome/free-solid-svg-icons";

import "./Reports.css";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Dropdown, notification, Select } from "antd";
import { DeviceType, ReportTypes } from "../../common/common";
import { DeviceModels } from "../../utils/mockup";
import { getDevices } from "../../redux/actions/devices";
import { exportReports } from "../../services/axios";
import CustomDatePicker from "../commons/datepicker/CustomDatePicker";

const Reports = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const dispatch = useDispatch();
  const devices = useSelector((state) => state.devicesList.devices);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchPlateText, setSearchPlateText] = useState("");
  const [searchDeviceText, setSearchDeviceText] = useState("");
  const [searchModelText, setSearchModelText] = useState(null);
  const [searchCompanyText, setSearchCompanyText] = useState("");
  const [searchFold, setSearchFold] = useState(true);

  const [devicesData, setDevicesData] = useState(devices);
  const [checkedList, setCheckedList] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);

  const [checkedTyes, setCheckedTypes] = useState(ReportTypes);

  useEffect(() => {
    (async () => {
      dispatch(getDevices(false));
    })();
  }, []);

  useEffect(() => {
    setDevicesData(
      devices?.filter((item) => {
        return (
          (searchPlateText == "" || item.vehicleName.toLowerCase().includes(searchPlateText.toLowerCase())) &&
          (searchDeviceText == "" || item.deviceType === searchDeviceText) &&
          (searchModelText == "" || searchModelText == null || item.deviceModel === searchModelText)
        );
      })
    );
  }, [devices, searchPlateText, searchDeviceText, searchModelText, searchCompanyText]);

  useEffect(() => {
    let allChecked = true;
    for (const device of devicesData) {
      if (!checkedList?.find(imei => imei == device.deviceImei)) {
        allChecked = false;
        break;
      }
    }
    setCheckedAll(allChecked);
  }, [checkedList, devicesData]);

  const handleCheck = (checked, item) => {
    const checkedDevices = [...checkedList];
    const index = checkedDevices.findIndex(d => d == item.deviceImei);
    if (index != -1) {
      checkedDevices.splice(index, 1);
    }
    if (checked) {
      checkedDevices.push(item.deviceImei);
    }
    setCheckedList(checkedDevices);
  }
  const handleCheckAll = (checked) => {
    if (checked) {
      setCheckedList(devicesData?.map(item => item.deviceImei));
    } else {
      setCheckedList([]);
    }
    setCheckedAll(checked);
  }

  const handlePlate = (event) => {
    const val = event.target.value;
    setSearchPlateText(val);
  };

  const handleDevice = (event) => {
    const val = event.target.value;
    setSearchDeviceText(val);
  };

  const handleModel = (value) => {
    setSearchModelText(value);
  };

  const handleCompany = (event) => {
    const val = event.target.value;
    setSearchCompanyText(val);
  };

  const handleClear = () => {
    setSearchPlateText("");
    setSearchDeviceText("");
    setSearchModelText("");
    setSearchCompanyText("");
    setStartDate(null);
    setEndDate(null);
  };

  const handleExport = async () => {
    let msg = null;
    if (checkedList.length == 0) {
      msg = "Please select the vehicles!";
    } else if (checkedTyes.length == 0) {
      msg = "Please select the report types!";
    } else if (!startDate) {
      msg = "Please select the Start date";
    } else if (!endDate) {
      msg = "Please select the End date";
    }
    if (msg != null) {
      notification.error({
        description: msg
      });
      return;
    }

    const res = await exportReports({
      deviceImeis: checkedList,
      reportTypes: checkedTyes,
      fromDate: startDate,
      toDate: endDate
    });
    if (res.status == 200) {
      window.open(process.env.REACT_APP_URL + "/" + res.data.url, "_blank");
    }
  }

  return (
    <div className="reports-main w-100 h-100">
      {!isMobile ? (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="sub1-div1 d-flex justify-content-between align-items-center w-100">
            <p className="px-5 text-white d-flex justify-content-center align-items-center">
              Total Vehicles Selected <span className="ms-3">{checkedList?.length}/{devicesData?.length}</span>
            </p>
            <div className="d-flex position-relative">
              <Dropdown
                menu={{ ReportTypes }}
                trigger={"click"}
                placement="bottomRight"
                dropdownRender={(menu) => {
                  return (
                    <div className="d-flex flex-column bg-white p-3 gap-2 portal-report-types">
                      {ReportTypes.map((item, index) => (
                        <Checkbox
                          key={index}
                          checked={checkedTyes.includes(item)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setCheckedTypes([...checkedTyes, item]);
                            } else {
                              setCheckedTypes(checkedTyes.filter(c => c != item))
                            }
                          }}
                        >
                          {item}
                        </Checkbox>
                      ))}
                    </div>
                  );
                }}
              >
                <div className="tab-button d-flex justify-content-center align-items-center px-4 mx-1 gap-2">
                  <img src="/assets/report.svg" alt="none" />
                  <label className="mb-0 text-white border-0">Report Type</label>
                  <img className="down-arrow" src="/assets/whiteDown.svg" alt="none" />
                </div>
              </Dropdown>

              <div className="tab-button d-flex justify-content-center align-items-center mx-1" onClick={handleExport}>
                <img src="/assets/export.svg" alt="none" />
                <button className="ms-2">Export</button>
              </div>
            </div>
          </div>
          <div className="sub2-div1 d-flex flex-column justify-content-end align-items-end w-100">
            <div className="subsub1-sub2-div1 bg-white d-flex flex-column py-2 w-100">
              <div className="sub1-subsub1-sub2-div1 d-flex align-items-center my-2">
                <img
                  src="/assets/Searchwithperson.svg"
                  alt="none"
                  className="search-icon-content"
                />
                <span>Search report</span>
              </div>
              <div className="sub2-subsub1-sub2-div1 d-flex justify-content-between align-items-center">
                <input
                  className="field-input"
                  type="text"
                  placeholder="Plate No."
                  value={searchPlateText}
                  onChange={handlePlate}
                />
                <select
                  className="field-select"
                  value={searchDeviceText}
                  onChange={handleDevice}
                >
                  <option value="">Device Type</option>
                  <option key={1} value={DeviceType.Teltonika}>{DeviceType.Teltonika}</option>
                  <option key={2} value={DeviceType.Ruptela}>{DeviceType.Ruptela}</option>
                </select>
                <Select
                  showSearch
                  allowClear
                  className="field-select"
                  placeholder="Device Model"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={DeviceModels.filter(item => (searchDeviceText == "" || item.type == searchDeviceText)).map((item) => ({
                    label: item.device, value: item.device
                  }))}
                  value={searchModelText}
                  onChange={(value) => handleModel(value)}
                />
                <div className="field-input date-picker-input">
                  <CustomDatePicker
                    placeholderText="From"
                    selectedDate={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={endDate}
                  />
                  <img src="/assets/calender.png" alt="cal" />
                </div>
                <div className="field-input date-picker-input">
                  <CustomDatePicker
                    placeholderText="To"
                    selectedDate={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                  />
                  <img src="/assets/calender.png" alt="cal" />
                </div>
                <select
                  className="field-select"
                  value={searchCompanyText}
                  onChange={handleCompany}
                >
                  <option value="">Company</option>
                </select>
                <div
                  className="tab-button d-flex justify-content-center align-items-center px-4 ms-4"
                  onClick={() => handleClear()}
                >
                  <img src="/assets/clear.svg" alt="none" />
                  <button>Clear</button>
                </div>
              </div>
            </div>
            <div className="div2">
              <div className="subsub1-sub1-reports-div2 py-3">
                <div className="mb-0 d-flex justify-content-center align-items-center">
                  <Checkbox
                    checked={checkedAll}
                    onChange={(e) => handleCheckAll(e.target.checked)}
                  />
                </div>
                <p className="mb-0 text-left ps-4">Plate No.</p>
                <p className="mb-0 text-left">Device Type</p>
                <p className="mb-0 text-left">Device Model</p>
                <p className="mb-0 text-left">Device IMEI</p>
                <p className="mb-0 text-left">Company</p>
              </div>
              <div className="sub2-div2 overflow-auto">
                {devicesData.map((item, index) => {
                  return (
                    <div key={index} id="import-reports">
                      <p id="sub1-import-reports">{index + 1}</p>
                      <div className="subsub1-sub2-reports-div2">
                        <div className="item justify-content-center">
                          <Checkbox
                            checked={checkedList.includes(item?.deviceImei)}
                            onChange={(e) => handleCheck(e.target.checked, item)}
                          />
                        </div>
                        <p className="item ps-4">{item?.vehicleName}</p>
                        <p className="item">{item?.deviceType}</p>
                        <p className="item">{item?.deviceModel}</p>
                        <p className="item">{item?.deviceImei}</p>
                        <p className="item">{item?.company?.name}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="tab mobile-title mb-4">
            <div
              aria-current="page"
              className="px-3 d-flex justify-content-center py-2"
              style={{
                color: "white",
                backgroundColor: "#1A2678",
                borderRadius: "30px",
              }}
            >
              <div className="d-flex content align-items-center">
                <FontAwesomeIcon className="me-4" icon={faFileContract} />
                Reports
              </div>
            </div>
          </div>
          <div className="sub1-div1 d-flex justify-content-between align-items-end w-100 p-0 my-3">
            <p className="px-3 text-white d-flex justify-content-evenly align-items-center mb-0">
              Total Vehicles Selected <span className="ms-3">{checkedList?.length}/{devicesData?.length}</span>
            </p>
            <div className="d-flex position-relative">
              <Dropdown
                menu={{ ReportTypes }}
                trigger={"click"}
                placement="bottomRight"
                dropdownRender={(menu) => {
                  return (
                    <div className="d-flex flex-column bg-white p-3 gap-2 portal-report-types">
                      {ReportTypes.map((item, index) => (
                        <Checkbox
                          key={index}>
                          {item}
                        </Checkbox>
                      ))}
                    </div>
                  );
                }}
              >
                <div className="tab-button d-flex justify-content-center align-items-center px-4 mx-1 gap-2">
                  <img src="/assets/report.svg" alt="none" />
                  <label className="mb-0 text-white border-0">Report Type</label>
                  <img className="down-arrow" src="/assets/whiteDown.svg" alt="none" />
                </div>
              </Dropdown>
              <div className="tab-button d-flex justify-content-center align-items-center mx-1" onClick={handleExport}>
                <img src="/assets/export.svg" alt="none" />
                <button>Export</button>
              </div>
            </div>
          </div>
          <div className="subsub1-sub2-div1 bg-white d-flex flex-column py-2 px-4 w-100">
            <div className="sub1-subsub1-sub2-div1 d-flex align-items-center p-2">
              <img
                src="/assets/Searchwithperson.svg"
                alt="none"
                className="search-icon-content"
              />
              <span>Search report</span>
              <img
                className="ms-auto accordion"
                style={!searchFold ? { transform: "rotate(180deg)" } : { transform: "none" }}
                src="/assets/arrow-down.png"
                alt="Arrow Down"
                onClick={() => setSearchFold(!searchFold)}
              />
            </div>
            {!searchFold && (
              <div className="sub2-subsub1-sub2-div1 d-flex flex-column px-0">
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <input
                      className="field-input"
                      type="text"
                      placeholder="Plate No."
                      value={searchPlateText}
                      onChange={handlePlate}
                    />
                  </div>
                  <div className="col-6 px-1">
                    <select
                      className="field-select"
                      value={searchDeviceText}
                      onChange={handleDevice}
                    >
                      <option value="">Device Type</option>
                      <option key={1} value={DeviceType.Teltonika}>{DeviceType.Teltonika}</option>
                      <option key={2} value={DeviceType.Ruptela}>{DeviceType.Ruptela}</option>
                    </select>
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <div className="field-input date-picker-input">
                      <CustomDatePicker
                        placeholderText="From"
                        selectedDate={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={endDate}
                      />
                      <img src="/assets/calender.png" alt="cal" />
                    </div>
                  </div>
                  <div className="col-6 px-1">
                    <div className="field-input date-picker-input">
                      <CustomDatePicker
                        placeholderText="To"
                        selectedDate={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                      />
                      <img src="/assets/calender.png" alt="cal" />
                    </div>
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <Select
                      showSearch
                      allowClear
                      className="field-select"
                      placeholder="Select Model"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={DeviceModels.filter(item => (searchDeviceText == "" || item.type == searchDeviceText)).map((item) => ({
                        label: item.device, value: item.device
                      }))}
                      value={searchModelText}
                      onChange={(value) => handleModel(value)}
                    />
                  </div>
                  <div className="col-6 px-1">
                    <select
                      className="field-select"
                      value={searchCompanyText}
                      onChange={handleCompany}
                    >
                      <option value="">Company</option>
                    </select>
                  </div>
                </div>
                <div
                  className="tab-button d-flex justify-content-center align-items-center px-4 ms-auto py-1"
                  onClick={() => handleClear()}
                >
                  <img src="/assets/clear.svg" alt="none" />
                  <button>Clear</button>
                </div>
              </div>
            )}
          </div>
          <div className={`sub2-div2 d-flex flex-column px-4 w-100 overflow-auto ms-0 ${searchFold && "folded"}`}>
            {devicesData?.map((item, index) => {
              return (
                <div id="import-reports" key={index}>
                  <p id="sub1-import-reports">{index + 1}</p>
                  <div className="subsub1-sub2-reports-div2 d-flex flex-column align-items-center py-2 px-3">
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Plate No.</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.vehicleName}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Device Type</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.deviceType}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Device Model</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.deviceModel}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">IMEI</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.deviceImei}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Company</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Reports;
