import React, { createRef, useEffect, useReducer, useRef, useState } from "react";
import { AdvancedMarker, ControlPosition, Map, MapControl, Marker, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

import VehicleMarker from "./VehicleMarker";
import { isCircle, isPolygon, isRectangle } from "./types.ts";
import { getMapZoomAndCenter } from "../../../utils/globals.js";

let trackPath = null;
let prevDrawingOverlay = null;

const _circleOptions = {
  clickable: true,
  editable: true,
  draggable: true,
  fillColor: "#5361E2",
  fillOpacity: 0.5,
  strokeColor: "#3A30A6",
};
const _rectangleOptions = {
  clickable: true,
  editable: true,
  draggable: true,
  fillColor: "#5361E2",
  fillOpacity: 0.5,
  strokeColor: "#3A30A6",
};
const _polygonOptions = {
  clickable: true,
  editable: true,
  draggable: true,
  fillColor: "#5361E2",
  fillOpacity: 0.5,
  strokeColor: "#3A30A6",
};
const _fixedOptions = {
  clickable: true,
  editable: false,
  draggable: false,
  // fillColor: "#f65b5b",
  // strokeColor: "#f65b5b",
};


const getApproximateCenter = function (overlay, bounds) {
  try {
    var boundsHeight = 0,
      boundsWidth = 0,
      centerPoint,
      heightIncr = 0,
      maxSearchSteps = 10,
      n = 1,
      northWest,
      polygonBounds = bounds,
      testPos,
      widthIncr = 0;

    // Get polygon Centroid
    centerPoint = polygonBounds.getCenter();

    if (google.maps.geometry.poly.containsLocation(centerPoint, overlay)) {
      // Nothing to do Centroid is in polygon use it as is
      return centerPoint;
    } else {
      maxSearchLoops = maxSearchSteps / 2;

      // Calculate NorthWest point so we can work out height of polygon NW->SE
      northWest = new google.maps.LatLng(polygonBounds.getNorthEast().lat(), polygonBounds.getSouthWest().lng());

      // Work out how tall and wide the bounds are and what our search
      // increment will be
      boundsHeight = google.maps.geometry.spherical.computeDistanceBetween(northWest, polygonBounds.getSouthWest());
      heightIncr = boundsHeight / maxSearchSteps;

      boundsWidth = google.maps.geometry.spherical.computeDistanceBetween(northWest, polygonBounds.getNorthEast());
      widthIncr = boundsWidth / maxSearchSteps;

      // Expand out from Centroid and find a point within polygon at 
      // 0, 90, 180, 270 degrees
      for (; n <= maxSearchSteps; n++) {
        // Test point North of Centroid
        testPos = google.maps.geometry.spherical.computeOffset(centerPoint, (heightIncr * n), 0);
        if (google.maps.geometry.poly.containsLocation(testPos, overlay)) {
          break;
        }

        // Test point East of Centroid
        testPos = google.maps.geometry.spherical.computeOffset(centerPoint, (widthIncr * n), 90);
        if (google.maps.geometry.poly.containsLocation(testPos, overlay)) {
          break;
        }

        // Test point South of Centroid
        testPos = google.maps.geometry.spherical.computeOffset(centerPoint, (heightIncr * n), 180);
        if (google.maps.geometry.poly.containsLocation(testPos, overlay)) {
          break;
        }

        // Test point West of Centroid
        testPos = google.maps.geometry.spherical.computeOffset(centerPoint, (widthIncr * n), 270);
        if (google.maps.geometry.poly.containsLocation(testPos, overlay)) {
          break;
        }
      }

      return (testPos);
    }
  } catch (err) { }

  return null;
};

const getGeometryCenter = (type, overlay) => {
  if (type == "circle") {
    return overlay.getCenter();
  } else if (type == "rectangle") {
    return overlay.getBounds().getCenter();
  } else if (type == "polygon") {
    var bounds = new google.maps.LatLngBounds();
    overlay.getPath().forEach(function (element, index) {
      bounds.extend(element)
    });
    return getApproximateCenter(overlay, bounds);
  }
  return null;
}

const getOverlay = (geoItem) => {
  let overlay = null;
  if (geoItem && geoItem.geometry) {
    if (geoItem.type == "circle") {
      overlay = new google.maps.Circle({
        ..._circleOptions,
        ..._fixedOptions,
        center: geoItem.geometry.center,
        radius: geoItem.geometry.radius,
      });
    } else if (geoItem.type == "rectangle") {
      overlay = new google.maps.Rectangle({
        ..._rectangleOptions,
        ..._fixedOptions,
        bounds: geoItem.geometry.bounds
      });
    } else if (geoItem.type == "polygon") {
      overlay = new google.maps.Polygon({
        ..._polygonOptions,
        ..._fixedOptions,
        paths: geoItem.geometry.path
      });
    }
  }
  return overlay;
}


const GoogleMap = ({ isShowAll, devices, point, selectImei, trackPoints, isDrawing, geofences, selectedGeoitem, onSelectGeoitem, drawingType, onUpdateGeometry }) => {

  const map = useMap();
  const drawing = useMapsLibrary('drawing');
  const marker = useMapsLibrary('marker');
  const geometry = useMapsLibrary('geometry');

  const [drawingManager, setDrawingManager] = useState(null);

  const [center, setCenter] = useState(point ? point : { lat: 24.299615, lng: 54.5632233 });
  const [zoom, setZoom] = useState(16);
  const [heading, setHeading] = useState(0);

  const [selectedImei, setSelectedImei] = useState(null);

  useEffect(() => {
    if (!map || !drawing || !isDrawing) return;

    const newDrawingManager = new drawing.DrawingManager({
      map,
      drawingMode: drawingType,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          drawing.OverlayType.CIRCLE,
          drawing.OverlayType.POLYGON,
          drawing.OverlayType.RECTANGLE
        ]
      },
      circleOptions: _circleOptions,
      polygonOptions: _polygonOptions,
      rectangleOptions: _rectangleOptions,
    });

    setDrawingManager(newDrawingManager);
    return () => {
      newDrawingManager.setMap(null);
      setDrawingManager(null);
    };
  }, [isDrawing, drawing, map]);

  useEffect(() => {
    if (!isDrawing || !drawingManager) {
      return;
    }
    if (drawingType == "none") {
      drawingManager.setDrawingMode(null);
    } else {
      drawingManager.setDrawingMode(drawingType);
    }
  }, [isDrawing, drawingType]);

  useEffect(() => {
    if (!map || !drawingManager) return;

    const eventListeners = [];

    const addUpdateListener = (eventName, overlay, type) => {
      const updateListener = google.maps.event.addListener(
        overlay,
        eventName,
        () => {
          const snapshot = {};
          if (isCircle(overlay)) {
            snapshot.center = overlay.getCenter()?.toJSON();
            snapshot.radius = overlay.getRadius();
          } else if (isPolygon(overlay)) {
            snapshot.path = overlay.getPath()?.getArray();
          } else if (isRectangle(overlay)) {
            snapshot.bounds = overlay.getBounds()?.toJSON();
          }
          onUpdateGeometry(type, snapshot);
        }
      );
      eventListeners.push(updateListener);
    };

    const overlayCompleteListener = google.maps.event.addListener(
      drawingManager,
      'overlaycomplete',
      (drawResult) => {
        switch (drawResult.type) {
          case google.maps.drawing.OverlayType.CIRCLE:
            ['center_changed', 'radius_changed'].forEach(eventName =>
              addUpdateListener(eventName, drawResult.overlay, drawResult.type)
            );
            break;
          case google.maps.drawing.OverlayType.POLYGON:
            ['mouseup'].forEach(eventName =>
              addUpdateListener(eventName, drawResult.overlay, drawResult.type)
            );
          case google.maps.drawing.OverlayType.RECTANGLE:
            ['bounds_changed', 'dragend'].forEach(eventName =>
              addUpdateListener(eventName, drawResult.overlay, drawResult.type)
            );
            break;
        }
        const snapshot = {};
        const overlay = drawResult.overlay;
        if (isCircle(overlay)) {
          snapshot.center = overlay.getCenter()?.toJSON();
          snapshot.radius = overlay.getRadius();
        } else if (isPolygon(overlay)) {
          snapshot.path = overlay.getPath()?.getArray();
        } else if (isRectangle(overlay)) {
          snapshot.bounds = overlay.getBounds()?.toJSON();
        }
        onUpdateGeometry(drawResult.type, snapshot);

        if (prevDrawingOverlay) {
          prevDrawingOverlay.setMap(null);
        }
        prevDrawingOverlay = drawResult.overlay;
      }
    );
    eventListeners.push(overlayCompleteListener);


    if (geometry && selectedGeoitem) {
      if (prevDrawingOverlay) {
        prevDrawingOverlay.setMap(null);
      }

      prevDrawingOverlay = getOverlay(selectedGeoitem);
      if (prevDrawingOverlay) {
        map.setCenter(getGeometryCenter(selectedGeoitem.type, prevDrawingOverlay));
        setZoom(16);

        prevDrawingOverlay.setEditable(true);
        prevDrawingOverlay.setDraggable(true);
        prevDrawingOverlay.setMap(map);
        drawingManager.setDrawingMode(null);

        switch (selectedGeoitem.type) {
          case google.maps.drawing.OverlayType.CIRCLE:
            ['center_changed', 'radius_changed'].forEach(eventName =>
              addUpdateListener(eventName, prevDrawingOverlay, selectedGeoitem.type)
            );
            break;
          case google.maps.drawing.OverlayType.POLYGON:
            ['mouseup'].forEach(eventName =>
              addUpdateListener(eventName, prevDrawingOverlay, selectedGeoitem.type)
            );
          case google.maps.drawing.OverlayType.RECTANGLE:
            ['bounds_changed', 'dragend'].forEach(eventName =>
              addUpdateListener(eventName, prevDrawingOverlay, selectedGeoitem.type)
            );
            break;
        }
      }
    }

    return () => {
      eventListeners.forEach(listener =>
        google.maps.event.removeListener(listener)
      );

      if (prevDrawingOverlay) {
        prevDrawingOverlay.setMap(null);
        prevDrawingOverlay = null;
      }
    };
  }, [map, drawingManager, geometry, selectedGeoitem]);

  useEffect(() => {
    if (!map || !marker || !geometry || !drawingManager || !geofences) return;

    const currentOverlays = [];
    const eventListeners = [];
    for (const item of geofences) {
      if (!item.geometry) {
        continue;
      }

      let overlay = getOverlay(item);
      if (overlay) {
        if (item != selectedGeoitem) {
          overlay.setMap(map);
          currentOverlays.push(overlay);
          const listener = google.maps.event.addListener(overlay, "mouseup", (e) => {
            onSelectGeoitem(item);
          });
          eventListeners.push(listener);
        }

        // add label
        const geoitemLabel = document.createElement('div');
        geoitemLabel.className = 'fw-bold fs-4 text-danger';
        geoitemLabel.textContent = item.name;
        let overlay2 = new marker.AdvancedMarkerElement({
          position: getGeometryCenter(item.type, overlay),
          content: geoitemLabel,
        });
        overlay2.setMap(map);
        currentOverlays.push(overlay2);
      }
    }

    return () => {
      for (const overlay of currentOverlays) {
        overlay.setMap(null);
      }
      eventListeners.forEach(listener =>
        google.maps.event.removeListener(listener)
      );
      if (prevDrawingOverlay) {
        prevDrawingOverlay.setMap(null);
        prevDrawingOverlay = null;
      }
    };

  }, [map, marker, geometry, drawingManager, geofences, selectedGeoitem]);


  useEffect(() => {
    if (map && point) {
      map.setCenter(point);
      setZoom(16);
      // setCenter(point);
    }
  }, [point]);

  useEffect(() => {
    if (!map) {
      return;
    }

    if (trackPath != null) {
      trackPath.setMap(null);
      trackPath = null;
    }

    if (trackPoints && trackPoints.length > 0) {
      trackPath = new google.maps.Polyline({
        path: trackPoints,
        geodesic: true,
        strokeColor: "#005EEC",
        strokeOpacity: 1.0,
        strokeWeight: 5,
      });
      trackPath.setMap(map);
    }
  }, [trackPoints]);


  const vehMarkerRefs = useRef({})
  useEffect(() => {
    if (selectedImei != null) {
      const device = devices?.find(item => item.deviceImei == selectedImei);
      if (device && map) {
        map.setCenter({
          lat: device.lat,
          lng: device.lng
        });
        setHeading(device.angle);
      }
    }

    const markers = [];
    let markerClusterer = null;
    if (map && marker && devices?.length > 1) {
      for (const device of devices) {
        if (!device.lat || !device.lng) {
          continue;
        }

        const vehMarker = vehMarkerRefs?.current?.[device.deviceImei];
        if (vehMarker) {
          markers.push(vehMarker);
        }
      }
      markerClusterer = new MarkerClusterer({ markers, map });
    }
    return () => {
      for (const vehMarker of markers) {
        vehMarker.setMap(null);
      }
      if (markerClusterer) {
        markerClusterer.setMap(null);
      }
    }
  }, [map, marker, devices]);

  useEffect(() => {
    if (!selectedImei) {
      setHeading(0);
    } else {
      const device = devices?.find(item => item.deviceImei == selectedImei);
      if (device) {
        setHeading(device.angle);
      }
    }
  }, [selectedImei]);

  const handleSelect = (deviceImei, e) => {
    if (deviceImei == selectedImei) {
      setSelectedImei(null);
    } else {
      setSelectedImei(deviceImei);
    }
  }

  useEffect(() => {
    setSelectedImei(selectImei);
  }, [selectImei]);

  useEffect(() => {
    if (!map) {
      return;
    }

    if (isShowAll && devices?.length > 0) {
      let result = getMapZoomAndCenter(devices, {
        width: map.getDiv().clientWidth,
        height: map.getDiv().clientHeight
      });
      // map.setCenter({
      //   lat: result.lat,
      //   lng: result.lng
      // });
      // setZoom(result.zoom);

      map.fitBounds(new google.maps.LatLngBounds(
        { lat: result.bounds[0], lng: result.bounds[1] },
        { lat: result.bounds[2], lng: result.bounds[3] },
      ), 100);
    }
  }, [isShowAll])

  return (
    <>
      <Map mapId={"90f87356969d889c"}
        zoom={zoom}
        onZoomChanged={(e) => setZoom(e.detail.zoom)}
        heading={heading}
        onHeadingChanged={(e) => setHeading(e.detail.heading)}
        defaultCenter={center}
        onClick={() => setSelectedImei(null)}
        mapTypeControl={false}
        disableDefaultUI={false}
      >
        <MapControl position={ControlPosition.RIGHT_BOTTOM}>
          <div className="map-control-button" onClick={() => setHeading(heading + 10)}>
            <FontAwesomeIcon icon={faRotateLeft} />
          </div>
          <div className="map-control-button border-top" onClick={() => setHeading(heading - 10)}>
            <FontAwesomeIcon icon={faRotateRight} />
          </div>
        </MapControl>

        {devices?.map((device, index) => (
          (device.lat && device.lng) ?
            <AdvancedMarker
              // anchor={{ u: 0.5, v: 0.5 }}
              key={device.deviceImei}
              position={{ lat: device.lat, lng: device.lng }}
              clickable={true}
              onClick={e => handleSelect(device.deviceImei, e)}
              ref={(ele) => {
                if (vehMarkerRefs.current) {
                  vehMarkerRefs.current[device.deviceImei] = ele;
                }
              }}
            >
              <VehicleMarker
                key={device.deviceImei}
                vehicle={device} selected={device.deviceImei == selectedImei}
                zoom={zoom}
                angle={heading}
              />
            </AdvancedMarker>
            : <></>
        ))}

        {trackPoints?.length > 0 &&
          <>
            <Marker
              position={{
                lat: trackPoints[0].lat,
                lng: trackPoints[0].lng
              }}
              label={"S"} />
            <Marker
              position={{
                lat: trackPoints[trackPoints.length - 1].lat,
                lng: trackPoints[trackPoints.length - 1].lng
              }}
              label={"E"} />
          </>
        }
      </Map>
    </>
  );
};

export default GoogleMap;
