import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Dropdown, Input, Modal, notification, Radio, Space } from 'antd';
import { DeviceType, IOPins, RuleTypes } from '../../../common/common';
import { createRule, updateRule } from '../../../services/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const _initValues = {
    _id: null,
    name: "",
    type: RuleTypes.Custom,
    alertText: "",
    isTeltonika: true,
    isRuptela: false,
    alertNotification: true,
    alertEmail: false,
    alertSMS: false,
    ioPin: "DIN1",
    pinStatus: 0,
    threshold: "",
    ioPin2: "DIN1",
    pinStatus2: 0,
    threshold2: "",
};
const CustomRuleEdit = ({ isShowModal, onClose, rule }) => {

    const [values, setValues] = useState(_initValues);
    const [greaterValue, setGreaterValue] = useState("");
    const [lessValue, setLessValue] = useState("");
    const [greaterValue2, setGreaterValue2] = useState("");
    const [lessValue2, setLessValue2] = useState("");

    useEffect(() => {
        if (rule) {
            setValues(rule);
            setGreaterValue(rule.threshold);
            setLessValue(rule.threshold);
            setGreaterValue2(rule.threshold2);
            setLessValue2(rule.threshold2);
        } else {
            setValues(_initValues);
            setGreaterValue("");
            setLessValue("");
            setGreaterValue2("");
            setLessValue2("");
        }
    }, [rule]);

    const handleValue = (field, value) => {
        setValues(prev => ({
            ...prev,
            [field]: value
        }));
    }

    const handleSubmit = async () => {
        let msg = null;
        if (values.name.length == 0) {
            msg = "Please input the Rule name";
        } else if (!values.isTeltonika && !values.isRuptela) {
            msg = "Please select the device brand";
        }

        if (msg != null) {
            notification.error({
                description: msg,
            });
            return;
        }

        let res = null;
        if (rule) {
            res = await updateRule(rule._id, {
                ...values,
                threshold: (values.pinStatus == 1 ? greaterValue : lessValue),
                threshold2: (values.pinStatus2 == 1 ? greaterValue2 : lessValue2)
            });
        } else {
            res = await createRule({
                ...values,
                threshold: (values.pinStatus == 1 ? greaterValue : lessValue),
                threshold2: (values.pinStatus2 == 1 ? greaterValue2 : lessValue2),
            });
        }
        if (res.status == 200) {
            notification.success({
                description: "Updated successfully!",
            });
            onClose(true);
        } else {
            notification.error({
                description: "Something went wrong!",
            });
        }
    }

    return (
        <Modal
            title={(
                <div className='position-relative text-center'>
                    <span className="fw-normal text-danger position-absolute top-0 start-0 mt-2 ms-4" style={{ textDecoration: "underline" }}>
                        {rule ? 'Edit' : 'Add'} Rule
                    </span>
                    {rule ?
                        <span className='bg-danger text-white rounded-3 px-4 py-2' style={{ fontSize: "1.2rem" }}>
                            {rule?.name}
                        </span> : <span>&nbsp;</span>
                    }
                </div>
            )}
            centered
            open={isShowModal}
            onOk={() => onClose()}
            onCancel={() => onClose()}
            width={750}
            style={{ maxWidth: "100%" }}
            footer={false}
        >
            <div className='pt-4 rule-edit-modal'>
                <div className='row mb-5'>
                    <div className='col-4'>
                        <Input
                            className='text-center'
                            placeholder='Rule Name'
                            size='large'
                            value={values.name}
                            onChange={(e) => handleValue('name', e.target.value)}
                        />
                    </div>
                    <div className='col-4'>
                        <div className='bg-body-tertiary rounded-4 d-flex flex-column align-items-center justify-content-center gap-3 py-3'>
                            <div>Device Brand</div>
                            <Checkbox
                                checked={values.isTeltonika}
                                onChange={(e) => handleValue('isTeltonika', e.target.checked)}
                            >
                                {DeviceType.Teltonika}
                            </Checkbox>
                            <Checkbox
                                checked={values.isRuptela}
                                onChange={(e) => handleValue('isRuptela', e.target.checked)}
                                className='me-3'
                            >
                                {DeviceType.Ruptela}
                            </Checkbox>
                        </div>
                    </div>
                    <div className='col-4'>
                        <Input
                            className='text-center'
                            placeholder='Alert Text'
                            size='large'
                            value={values.alertText}
                            onChange={(e) => handleValue('alertText', e.target.value)}
                        />
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className='col-8'>
                        {values.isTeltonika &&
                            <div className='bg-body-tertiary rounded-4 py-4 px-4 mb-5'>
                                <div className="fw-bold text-danger">Teltonika</div>
                                <div className='row'>
                                    <div className='col'>
                                        <Dropdown
                                            trigger={"click"}
                                            placement="bottom"
                                            dropdownRender={(menu) => {
                                                return (
                                                    <div className='bg-white py-3 px-5 rounded-4 border'>
                                                        <Radio.Group onChange={(e) => handleValue('ioPin', e.target.value)} value={values.ioPin}>
                                                            <Space direction="vertical">
                                                                {IOPins.map((pin, index) => (
                                                                    <Radio key={index} value={pin}>{pin == "DIN1" ? "DIN1 (Ignition)" : pin}</Radio>
                                                                ))}
                                                            </Space>
                                                        </Radio.Group>
                                                    </div>
                                                );
                                            }}
                                        >
                                            <div className='position-relative bg-white py-2 ps-4 rounded-4 cursor-pointer'>
                                                <span className='ms-3'>I/O Pin</span>
                                                <FontAwesomeIcon icon={faAngleDown} className='position-absolute top-0 end-0 mt-3 me-3' />
                                            </div>
                                        </Dropdown>
                                        {values.ioPin &&
                                            <div className='bg-white py-2 px-5 mt-2 rounded-4 border'>
                                                <Radio checked>{values.ioPin}</Radio>
                                            </div>
                                        }
                                    </div>
                                    <div className='col'>
                                        <Dropdown
                                            trigger={"click"}
                                            placement="bottom"
                                            dropdownRender={(menu) => {
                                                return (
                                                    <div className='bg-white py-3 px-3 rounded-4 border'>
                                                        <Radio.Group className='w-100' onChange={(e) => handleValue('pinStatus', e.target.value)} value={values.pinStatus}>
                                                            <Space direction="vertical" className='w-100'>
                                                                {values.ioPin?.startsWith("A") ? <>
                                                                    <div className='w-100 d-flex justify-content-between'>
                                                                        <Radio value={1}>Greater Than</Radio>
                                                                        <Input
                                                                            size='small' placeholder='value' style={{ width: 50 }} className='text-center'
                                                                            disabled={values.pinStatus != 1}
                                                                            value={greaterValue}
                                                                            onChange={e => setGreaterValue(e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <div className='w-100 d-flex justify-content-between'>
                                                                        <Radio value={0}>Less Than</Radio>
                                                                        <Input
                                                                            size='small' placeholder='value' style={{ width: 50 }} className='text-center'
                                                                            disabled={values.pinStatus != 0}
                                                                            value={lessValue}
                                                                            onChange={e => setLessValue(e.target.value)}
                                                                        />
                                                                    </div>
                                                                </> : <>
                                                                    <Radio value={1}>High</Radio>
                                                                    <Radio value={0}>Low</Radio>
                                                                    <Radio value={2}>Both</Radio>
                                                                </>}
                                                            </Space>
                                                        </Radio.Group>
                                                    </div>
                                                );
                                            }}
                                        >
                                            <div className='position-relative bg-white py-2 ps-4 rounded-4 cursor-pointer'>
                                                <span className='ms-3'>I/O Status</span>
                                                <FontAwesomeIcon icon={faAngleDown} className='position-absolute top-0 end-0 mt-3 me-3' />
                                            </div>
                                        </Dropdown>
                                        {values.ioPin &&
                                            <div className='bg-white py-2 px-3 mt-2 rounded-4 border'>
                                                {values.ioPin?.startsWith("A") ? <>
                                                    <div className='w-100 d-flex justify-content-between'>
                                                        <Radio checked>{values.pinStatus == 1 ? 'Greater Than' : 'Less Than'}</Radio>
                                                        <Input
                                                            size='small' placeholder='value' style={{ width: 50 }} className='text-center'
                                                            value={values.pinStatus == 1 ? greaterValue : lessValue}
                                                            onChange={e => {
                                                                if (values.pinStatus == 1) {
                                                                    setGreaterValue(e.target.value);
                                                                } else {
                                                                    setLessValue(e.target.value);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </> : <>
                                                    <Radio checked>
                                                        {values.pinStatus == 0 ? 'Low' : values.pinStatus == 1 ? 'High' : 'Both'}
                                                    </Radio>
                                                </>}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        {values.isRuptela &&
                            <div className='bg-body-tertiary rounded-4 py-4 px-4'>
                                <div className="fw-bold text-danger">Ruptela</div>
                                <div className='row'>
                                    <div className='col'>
                                        <Dropdown
                                            trigger={"click"}
                                            placement="bottom"
                                            dropdownRender={(menu) => {
                                                return (
                                                    <div className='bg-white py-3 px-5 rounded-4 border'>
                                                        <Radio.Group onChange={(e) => handleValue('ioPin2', e.target.value)} value={values.ioPin2}>
                                                            <Space direction="vertical">
                                                                {IOPins.map((pin, index) => (
                                                                    <Radio key={index} value={pin}>{pin == "DIN1" ? "DIN1 (Ignition)" : pin}</Radio>
                                                                ))}
                                                            </Space>
                                                        </Radio.Group>
                                                    </div>
                                                );
                                            }}
                                        >
                                            <div className='position-relative bg-white py-2 ps-4 rounded-4 cursor-pointer'>
                                                <span className='ms-3'>I/O Pin</span>
                                                <FontAwesomeIcon icon={faAngleDown} className='position-absolute top-0 end-0 mt-3 me-3' />
                                            </div>
                                        </Dropdown>
                                        {values.ioPin2 &&
                                            <div className='bg-white py-2 px-5 mt-2 rounded-4 border'>
                                                <Radio checked>{values.ioPin2}</Radio>
                                            </div>
                                        }
                                    </div>
                                    <div className='col'>
                                        <Dropdown
                                            trigger={"click"}
                                            placement="bottom"
                                            dropdownRender={(menu) => {
                                                return (
                                                    <div className='bg-white py-3 px-3 rounded-4 border'>
                                                        <Radio.Group className='w-100' onChange={(e) => handleValue('pinStatus2', e.target.value)} value={values.pinStatus2}>
                                                            <Space direction="vertical" className='w-100'>
                                                                {values.ioPin2?.startsWith("A") ? <>
                                                                    <div className='w-100 d-flex justify-content-between'>
                                                                        <Radio value={1}>Greater Than</Radio>
                                                                        <Input
                                                                            size='small' placeholder='value' style={{ width: 50 }} className='text-center'
                                                                            disabled={values.pinStatus2 != 1}
                                                                            value={greaterValue2}
                                                                            onChange={e => setGreaterValue2(e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <div className='w-100 d-flex justify-content-between'>
                                                                        <Radio value={0}>Less Than</Radio>
                                                                        <Input
                                                                            size='small' placeholder='value' style={{ width: 50 }} className='text-center'
                                                                            disabled={values.pinStatus2 != 0}
                                                                            value={lessValue2}
                                                                            onChange={e => setLessValue2(e.target.value)}
                                                                        />
                                                                    </div>
                                                                </> : <>
                                                                    <Radio value={1}>High</Radio>
                                                                    <Radio value={0}>Low</Radio>
                                                                    <Radio value={2}>Both</Radio>
                                                                </>}
                                                            </Space>
                                                        </Radio.Group>
                                                    </div>
                                                );
                                            }}
                                        >
                                            <div className='position-relative bg-white py-2 ps-4 rounded-4 cursor-pointer'>
                                                <span className='ms-3'>I/O Status</span>
                                                <FontAwesomeIcon icon={faAngleDown} className='position-absolute top-0 end-0 mt-3 me-3' />
                                            </div>
                                        </Dropdown>
                                        {values.ioPin2 &&
                                            <div className='bg-white py-2 px-3 mt-2 rounded-4 border'>
                                                {values.ioPin2?.startsWith("A") ? <>
                                                    <div className='w-100 d-flex justify-content-between'>
                                                        <Radio checked>{values.pinStatus2 == 1 ? 'Greater Than' : 'Less Than'}</Radio>
                                                        <Input
                                                            size='small' placeholder='value' style={{ width: 50 }} className='text-center'
                                                            value={values.pinStatus2 == 1 ? greaterValue2 : lessValue2}
                                                            onChange={e => {
                                                                if (values.pinStatus2 == 1) {
                                                                    setGreaterValue2(e.target.value);
                                                                } else {
                                                                    setLessValue2(e.target.value);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </> : <>
                                                    <Radio checked>
                                                        {values.pinStatus2 == 0 ? 'Low' : values.pinStatus2 == 1 ? 'High' : 'Both'}
                                                    </Radio>
                                                </>}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='col-4'>
                        <div className='rounded-4 border border-light-subtle d-flex flex-column gap-3 py-3 px-4'>
                            <div className=''>Alert Notifications:</div>
                            <Checkbox
                                checked={values.alertNotification}
                                className='ms-3'
                                onChange={(e) => handleValue('alertNotification', e.target.checked)}
                            >
                                <span className='ms-4'>Notification</span>
                            </Checkbox>
                            <Checkbox
                                checked={values.alertEmail}
                                className='ms-3'
                                onChange={(e) => handleValue('alertEmail', e.target.checked)}
                            >
                                <span className='ms-4'>Email</span>
                            </Checkbox>
                            <Checkbox
                                checked={values.alertSMS}
                                className='ms-3'
                                onChange={(e) => handleValue('alertSMS', e.target.checked)}
                            >
                                <span className='ms-4'>SMS</span>
                            </Checkbox>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center gap-3">
                    <button className="tab-button text-white" onClick={() => onClose()}>Cancel</button>
                    <button className="tab-button text-white" onClick={() => handleSubmit()}>Save</button>
                </div>
            </div>
        </Modal>
    )
}

export default CustomRuleEdit;
