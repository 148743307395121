import { useRef, useState } from "react";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatePicker.css";

const CustomDatePicker = ({
    selectedDate,
    onChange,
    ...props
}) => {

    const [tempDate, setTempDate] = useState(null);

    const handleConfirm = () => {
        onChange(tempDate);
        setTempDate(null);

        // document.body.click();
        // document.body.dispatchEvent(new MouseEvent('click', { bubbles: true }));
        document.body.dispatchEvent(new MouseEvent('mousedown', { bubbles: true, cancelable: true }));
    };

    const handleCancel = () => {
        setTempDate(null);
    };

    return (
        <DatePicker
            selected={tempDate || selectedDate}
            onChange={(date) => setTempDate(date)}
            onClickOutside={handleCancel}
            dateFormat="dd/MM/yyyy h:mm aa"
            shouldCloseOnSelect={false}
            showTimeInput
            isClearable
            {...props}
        >
            <button className="date-picker-confirm" onClick={handleConfirm}>Confirm</button>
        </DatePicker>
    );
}

export default CustomDatePicker;