
const UserRole = {
    Admin: "Super Admin",
    Manager: "Admin",
    Client: "Client",
}

const DeviceType = {
    Teltonika: "Teltonika",
    Ruptela: "Ruptela",
}

const cmdType = {
    EngineOn: "EngineOn",
    DigitalOutput: "Digital Inputs/Outputs",
    DigitalInput: "Digital Input",
    BatteryLevel: "Battery Level",
    BatteryVoltage: "Battery Voltage",
    ExternalVoltage: "External Voltage",
    AnalogueInput: "Analogue Input",
    AxisX: "Axis X",
    AxisY: "Axis Y",
    AxisZ: "Axis Z",

    MotionDetection: "Motion Detection",
    SleepMode: "Sleep Mode",
    NetworkTimeProtocalServer: "Network Time Protocal Server",
    StaticNavigation: "Static Navigation",
    GnssSource: "GNSS Source",
    LedIndication: "LED Indication",
    BatteryChargeMode: "Battery charge mode",
    IgnitionDetectionSource: "Ignition Detection Source",
    AccelerometerCalibrationAndGravityFilter: "Accelerometer calibration and gravity filter",
    DataCodecToUseForDataTransmission: "Data codec to use for data transmission",
    GetverCommandResponse: "getver command response",
    AssistedGPSSettings: "Assisted GPS Settings",

    RecordsParameters: "Records Parameters",
    EnableConnectionOverTLS: "Enable connection over TLS",
    FirmwareOverTheAirWebService: "Firmware Over The Air web service",
    NetworkPing: "Network Ping",
    GprsServerSetup: "GPRS Server Setup",
    GprsSetup: "GPRS Setup",
    SecondaryGprsServerSetup: "Secondary GPRS Server Setup",
    MQTTSettings: "MQTT Settings",

    SmsReportSendingParams: "SMS report sending parameters",
    IncomingCallAction: "Incoming call action",
    PhoneNumbers: "Phone Numbers",
    SmsEventTimeZone: "SMS event Time Zone",
    AuthorizedNumbers: "Authorized Numbers",

    HomeGsmNetwork: "Home GSM Network",
    RoamingGsmNetworkSettings: "Roaming GSM Network Setting",
    UnknownGsmNetwork: "Unknown GSM Network",
    TripScenarioParam: "Trip Scenario Parameter",
    Odometer: "Odometer",
    TrackingOnDemandMode: "Tracking on Demand Mode",

    GreenDriving: "Green Driving",
    OverSpeeding: "OverSpeeding",
    DoutControlViaIgnition: "Dout Control Via Ignition",
    DoutControlViaCall: "Dout Control Via Call",
    IgnitionOnCounter: "Ignition ON Counter",
    NetworkJamming: "Network Jamming",
    GNSSJamming: "GNSS Jamming",
    IDReadNotification: "ID Read Notification",
    Immobilizer: "Immobilizer",
    SECO: "SECO",
    Dout1OutputType: "Dout1 Output Type",
    AuthorizedIDsList: "Authorized ID List",

    UnplugDetection: "Unplug Detection",
    TowingDetection: "Towing Detection",
    CrashDetection: "Crash Detection",
    ExcessiveIdlingDetection: "Excessive Idling Detection",

    CanParam: "CAN Parameters",
    ToggleCanControlToOpenUnlockAllDoors: "Tolggle CAN-CONTROL to open unlock all doors",
    ToggleCanControlToOpenUnlockTrunk: "Tolggle CAN-CONTROL to open unlock trunk",
    ToggleCanControlToBlockEngine: "Toggle CAN-CONTROL to block engine",
    LVCANSetup: "LVCAN setup",
    ToggleCanControlToCloselockAllDoors: "Tolggle CAN-CONTROL to close lock all doors",
    ToggleCanControlToFlashLights: "Tolggle CAN-CONTROL to flash lights",
    ToggleCanControlToUnblockEngine: "Toggle CAN-CONTROL to unblock engine",

    BluetoothWorkMode: "Bluetooth work mode",
    BLEcommonSetting: "BLE Common Settings",
    AutoConnectToExternalDevice: "Auto Connect to external device",
    AuthorizedBeaconsList: "Authorized beacons list",
    BeaconSettings: "Beacon Settings",
    BLESerialEncryption: "BLE Serial Encryption",
    EYESensor: "EYE Sensor",

    ReqGPSDataTime: "Request current GPS Date and Time",
    FormatSDcard: "Format SD card",
    RestartDev: "Restart the device",
    ConnectToFotaWeb: "Connect to Fota Web immediately",
    ForceDevToGenReport: "Force device to Generate a report",
    GetimeiccidCmd: "Send getimeiccid command",

    NetworkMode: "Network mode",
    LoTNetworkMode: "loT Network Mode",
    BandSelectionMode: "Band Seletion Mode",

    Custom: "Custom"
}


const ReportTypes = [
    "Fuel",
    "Idle",
    "Connectivity",
    "Movement",
    "Ignition Status",
    "Ignition Control",
    "OverSpeed",
    "Crash",
    "Geofence",
    "GPS Status",
    "Low Battery",
    "Door Status",
    "Temperature",
    "Trip Odometer",
];

const RuleTypes = {
    DeviceStatus: "DeviceStatus",
    OverSpeed: "OverSpeed",
    Geofence: "Geofence",
    Temperature: "TemperatureStatus",
    FuelStatus: "FuelStatus",
    IgnitionStatus: "IgnitionStatus",
    IgnitionControl: "IgnitionControl",
    DoorStatus: "DoorStatus",
    DoorLockStatus: "DoorLockStatus",
    Movement: "Movement",
    Stop: "Stop",
    Towing: "TowingDetected",
    Crash: "CrashDetected",
    BatteryLow: "BatteryLow",
    GPSStatus: "GPSStatus",
    Custom: "Custom",
};

const IOPins = [
    "DIN1",
    "DIN2",
    "DIN3",
    "DIN4",
    "DOUT1",
    "DOUT2",
    "DOUT3",
    "DOUT4",
    "AIN1",
    "AIN2",
    "AIN3",
    "AIN4",
];


const CommandPriorities = [
    { value: "", label: "" },
    { value: "0", label: "Disabled" },
    { value: "1", label: "Low" },
    { value: "2", label: "High" },
    { value: "3", label: "Panic" }
];

const CommandDoutControls = [
    { value: "", label: "" },
    { value: "0", label: "None" },
    { value: "1", label: "DOUT1" },
    { value: "2", label: "DOUT2" },
    { value: "3", label: "DOUT3" },
];

const CommandOperands = [
    { value: "", label: "" },
    { value: "0", label: "On Exit" },
    { value: "1", label: "On Enter" },
    { value: "2", label: "On Both" },
    { value: "3", label: "Monitoring" },
    { value: "4", label: "On Hysteresis" },
    { value: "5", label: "On Change" },
    { value: "6", label: "On Delta Change" },
];

const CommandPhones = [
    "", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10
];

const getCommandPhones = (gsmNumbers) => {
    if (gsmNumbers && gsmNumbers.length > 0) {
        return [{ value: "", label: "" }].concat(
            gsmNumbers.map((number, index) => ({
                value: index,
                label: number?.trim().length > 0 ? number : "-"
            }))
        );
    }
    return CommandPhones;
}


export {
    UserRole, DeviceType,
    cmdType, ReportTypes, RuleTypes, IOPins,
    CommandPriorities, CommandOperands, CommandPhones, CommandDoutControls,
    getCommandPhones,
};