import React from "react";
import { APIProvider } from '@vis.gl/react-google-maps';
import { useSelector } from "react-redux";
import GoogleMap from "./GoogleMap";
import MapBoxMap from "./MapBoxMap";

const MapContainer = ({ isShowAll = false, devices, point, selectImei, trackPoints, isDrawing = false, drawingType, geofences, selectedGeoitem, onSelectGeoitem, onUpdateGeometry }) => {

  // const gm = useSelector((state) => state.auth.gm);
  const gm = JSON.parse(localStorage.getItem('gm') ? localStorage.getItem('gm') : {});

  const mapMode = useSelector((state) => state.global.mapMode);

  return (
    mapMode === 1 ? (
      (gm?.g) ?
        <APIProvider apiKey={gm?.g}>
          <GoogleMap
            isShowAll={isShowAll}
            devices={devices}
            point={point}
            selectImei={selectImei}
            trackPoints={trackPoints}
            isDrawing={isDrawing}
            drawingType={drawingType}
            geofences={geofences}
            selectedGeoitem={selectedGeoitem}
            onSelectGeoitem={onSelectGeoitem}
            onUpdateGeometry={onUpdateGeometry} />
        </APIProvider>
        : <></>
    ) : (
      (gm?.m) ?
        <MapBoxMap
          apiKey={gm?.m}
          isShowAll={isShowAll}
          devices={devices}
          point={point}
          selectImei={selectImei}
          trackPoints={trackPoints}
          isDrawing={isDrawing}
          drawingType={drawingType}
          geofences={geofences}
          selectedGeoitem={selectedGeoitem}
          onSelectGeoitem={onSelectGeoitem}
          onUpdateGeometry={onUpdateGeometry} />
        : <></>
    )
  );
};

export default MapContainer;
